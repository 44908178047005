export const initialValues = {
  rut: '',
  name: '',
  lastname: '',
  address: '',
  phone: '',
  email: '',
  workstation_id: '',
  date_start: '',
}

export const sizes = [
  { value: 'XS', label: 'XS' },
  { value: 'S', label: 'S' },
  { value: 'M', label: 'M' },
  { value: 'L', label: 'L' },
  { value: 'XL', label: 'XL' },
  { value: 'XXL', label: 'XXL' },
]
