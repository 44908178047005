export const config = [
  [
    {
      input: 'CustomInput',
      key: 1,
      name: 'password',
      title: 'Contraseña Provisoria*',
      type: 'password',
      grid: 12,
    },
    {
      input: 'CustomInput',
      key: 2,
      name: 'new_password',
      title: 'Nueva contraseña*',
      type: 'password',
      grid: 12,
    },
    {
      input: 'CustomInput',
      key: 3,
      name: 'confirmation',
      title: 'Confirmar contraseña*',
      type: 'password',
      grid: 12,
    },
  ],
]
