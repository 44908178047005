export const areas = [
  {
    input: 'CustomSelect',
    key: 3,
    name: 'area_id',
    title: 'Áreas*',
    type: 'text',
    options: [],
    grid: 6,
  },
]

export const configForm = [
  [
    {
      input: 'CustomDatePicker',
      key: 1,
      name: 'date_start',
      title: 'Fecha de inicio*',
      grid: 6,
    },
    {
      input: 'CustomDatePicker',
      key: 2,
      name: 'date_end',
      title: 'Fecha termino*',
      grid: 6,
    },
  ],
]
