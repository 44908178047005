import { sizes } from './_initialValues'

export const config = [
  [
    {
      input: 'CustomInput',
      key: 1,
      name: 'name',
      title: 'Nombre*',
      type: 'text',
      grid: 6,
    },
    {
      input: 'CustomInput',
      key: 2,
      name: 'lastname',
      title: 'Apellido*',
      type: 'text',
      grid: 6,
    },
  ],
  [
    {
      input: 'CustomInputRUT',
      key: 3,
      name: 'rut',
      title: 'RUT*',
      type: 'text',
      grid: 6,
      placeholder: 'Ej: 99999999-9',
    },
    {
      input: 'CustomInput',
      key: 4,
      name: 'email',
      title: 'Correo electrónico*',
      type: 'email',
      grid: 6,
    },
  ],
  [
    {
      input: 'CustomInput',
      key: 5,
      name: 'address',
      title: 'Dirección*',
      type: 'text',
      grid: 6,
    },
    {
      input: 'CustomInputAddon',
      key: 6,
      name: 'phone',
      title: 'Teléfono de contacto*',
      type: 'phone',
      prepend: '+569',
      grid: 6,
    },
  ],
  [
    {
      input: 'CustomSelectCheckbox',
      key: 7,
      name: 'workstation_id',
      title: 'Puesto de trabajo',
      type: 'text',
      options: [],
      grid: 6,
    },
    {
      input: 'CustomDatePicker',
      key: 8,
      name: 'date_start',
      title: 'Fecha de inicio laboral*',
      grid: 6,
    },
  ],
  [
    {
      input: 'CustomInputAddon',
      key: 9,
      name: 'phone_emergency',
      title: 'Teléfono de emergencia*',
      type: 'phone',
      prepend: '+569',
      grid: 6,
    },
    {
      input: 'CustomInput',
      key: 10,
      name: 'height',
      title: 'Estatura (cm Ej: 180)',
      type: 'text',
      grid: 6,
    },
  ],
  [
    {
      input: 'CustomSelect',
      key: 11,
      name: 'size',
      title: 'Talla',
      type: 'text',
      options: sizes,
      grid: 4,
    },
    {
      input: 'CustomInput',
      key: 12,
      name: 'size_pants',
      title: 'Pantalón (Ej: 52)',
      type: 'text',
      grid: 4,
    },
    {
      input: 'CustomInput',
      key: 13,
      name: 'size_shoe',
      title: 'Nº Zapato (Ej: 44)',
      type: 'text',
      grid: 4,
    },
  ],
  [
    {
      input: 'CustomTextArea',
      key: 14,
      name: 'observation',
      title: 'Observaciones',
      rows: 5,
      grid: 12,
    },
  ],
]
