export const rules = {
  superadministrator: [
    'dashboard:superadmin',
    'enterprises:edit',
    'enterprises:delete',
    'enterprises:workon',
  ],
  administrator: [
    'dashboard:admin',
    'dashboard:manager',
    'areas:edit',
    'areas:delete',
    'workstations:add',
    'workstations:edit',
    'workstations:delete',
    'employees:add',
    'employees:edit',
    'employees:delete',
    'activities:add',
    'activities:edit',
    'activities:delete',
    'trainings:edit',
    'trainings:delete',
    'trainings:detail',
    'trainings:historical',
    'programs:add',
    'programs:edit',
    'programs:delete',
    'programs:detail',
    'programs_participants:edit',
    'programs_participants:delete',
    'programs:historical',
    'actions:edit',
    'actions:delete',
    'controls:add',
    'controls:edit',
    'controls:delete',
    'supervision:list',
    'supervision:detail',
  ],
  chief_of_area: [
    'dashboard:manager',
    'dashboard:chief_of_area',
    'trainings:edit',
    'trainings:delete',
    'trainings:detail',
    'trainings:historical',
    'programs:edit',
    'programs:delete',
    'programs:detail',
    'programs:historical',
    'controls:add',
    'controls:edit',
    'controls:delete',
    'supervision:list',
    'supervision:detail',
  ],
}
