import React from 'react'
import { CardBody, Col, Row } from 'reactstrap'

import { Formik, Form } from 'formik'
import { FormGroup, FormSubmit } from '../../..'
import { actionSchema } from './_validation'
import { initialValues } from './_initialValues'
import { config } from './_config'

const FormUI = (props) => {
  const {
    handleSubmit, placeholder, title, options, onClose,
  } = props
  return (

    <CardBody>
      <Formik
        initialValues={placeholder || initialValues}
        validationSchema={actionSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {() => (
          <Form id="form-actions-export">
            {
              config.map((row) => (
                <Row key={row[0].key}>
                  {
                    row.map((item) => {
                      if (options && item.options) {
                        Object.assign(item, { ...item, options })
                      }
                      return (
                        <Col sm={item.grid} key={item.key}>
                          <FormGroup {...item} />
                        </Col>
                      )
                    })
                  }
                </Row>
              ))
            }
            <FormSubmit onClose={onClose} title={title} />
          </Form>
        )}
      </Formik>
    </CardBody>

  )
}

export default FormUI
