export const config = [
  [
    {
      input: 'CustomInput',
      key: 4,
      name: 'name',
      title: 'Nombre relator*',
      type: 'text',
      grid: 6,
    },
    {
      input: 'CustomInputRUT',
      key: 3,
      name: 'rut',
      title: 'RUT*',
      type: 'text',
      grid: 6,
      small: 'Ej: 99999999-9',
    },
  ],
  [
    {
      input: 'CustomSignatureInput',
      key: 2,
      name: 'signature',
      title: 'Firma*',
      grid: 12,
    },
  ],
]
