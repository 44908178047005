export const cellRegExp = /^[0-9]{8}$/
export const phoneRegExp = /^[0-9]{9,11}$/
export const urlApi = 'api.sinjury.cl'
export const profiles = { superadministrator: 'Super Administrador', administrator: 'Administrador', chief_of_area: 'Jefe de Area' }
export const updatePasswordUrl = { superadministrator: 'admin/password', administrator: 'users/password', chief_of_area: 'users/password', employees: 'users/password' }
export const baseApiUrl = '/api/v1/'
export const semaphore = { high: 'danger', media: 'warning', middle: 'warning', low: 'success' }
export const semaphoreText = { high: 'Alto', media: 'Medio', middle: 'Medio', low: 'Bajo' }
export const assistText = { invited: 'Invitado', attended: 'Asistió', not_assist: 'No asistió' }
export const assistTextColor = { invited: 'warning', attended: 'success', not_assist: 'danger' }
export const semaphoreFields = ['priority', 'risk']
